/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import Axios from '@/helpers/axios-wrapper';
import {
  EMAIL,
  filterBreakdownQuery,
  mapSessionDates,
  processCampaign,
  processEmail,
  processEventData,
  processStatsData,
  SESSION,
} from '@/helpers/stats-utils';
import { SESSION_FETCH_LIMIT } from '@/store/shared';
import {
  IAnalyticsSnapshot,
  IBillingMetricsTimeSeries,
  IBillingMetricsTimeSeriesFilter,
  IBlockCount,
  IBoostStats,
  ICampaign,
  ICampaignEmailCount,
  ICooldownProjection,
  ICouponStats,
  IDataRecord,
  IDunningCampaignCount,
  IDunningCampaignFilter,
  IDunningTotalInvoicesDue,
  IEmail,
  IEmailCount,
  IEmailsByDate,
  IEvent,
  IEventFilter,
  IEventFilterOptions,
  IEventRollup,
  IInvoiceCount,
  IMatchedSession,
  IPauseStats,
  IReactivationCampaignCount,
  IReactivationEmailCount,
  IReactivationStats,
  ISentimentSession,
  ISentimentTrend,
  ISession,
  ISessionFilter,
  ISessionFilterValues,
  ISessionOutcomeCount,
  ISurveyResponseCount,
  ISurveyResponseRate,
} from '@/store/types';
import { timeDay, timeMinute, timeMonth, timeWeek } from 'd3-time';

const RESOURCE = '/api/stats';
const HEALTH_RESOURCE = '/api/health';

export default {
  /**
   * @param {String} customerId payment provider id of the customer
   */
  getCustomerProfile(customerId: string): any {
    // get mode from url params
    const mode = new URLSearchParams(window.location.search).get('mode');

    return Axios.get(`${RESOURCE}/customer-profile/${customerId}${mode?.toLowerCase() === 'test' ? '?mode=test' : ''}`).then((response: any) => {
      const activities = response.activities.map((activity: any) => {
        activity.timelineDate = new Date(activity.timelineDate);
        return activity;
      });
      return {
        customer: response.customer,
        activities,
      };
    });
  },
  /**
   * @param {String} customerId payment provider id of the customer
   */
  getCustomerHealth(customerId: string): any {
    // get mode from url params
    const mode = new URLSearchParams(window.location.search).get('mode');

    return Axios.get(`${HEALTH_RESOURCE}/customer/${customerId}${mode?.toLowerCase() === 'test' ? '?mode=test' : ''}`).then((response: any) => {
      return response;
    });
  },
  /**
   * @param {Date} startDate start datetime of sessions to fetch
   * @param {Date} endDate end datetime of sessions to fetch
   */
  listSessions(startDate: Date, endDate: Date, limit?: number): ISession[] {
    return Axios.get(
      `${RESOURCE}/sessions?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&limit=${limit || SESSION_FETCH_LIMIT}`
    ).then((data: any[]) => data.map(mapSessionDates));
  },
  monthlyAnalytics(): IAnalyticsSnapshot[] {
    return Axios.get(`${RESOURCE}/org-monthly-metrics`).then((data: any[]) => {
      data.forEach((d) => {
        d.startDate = new Date(d.date * 1000);
        d.endDate = timeMonth.ceil(d.startDate);
      });
      return data;
    });
  },
  couponStats(): ICouponStats[] {
    return Axios.get(`${RESOURCE}/org-coupon-stats`);
  },
  pauseStats(): IPauseStats {
    return Axios.get(`${RESOURCE}/org-pause-stats`);
  },
  materializedBoostedRevenue(): IBoostStats[] {
    return Axios.get(`${RESOURCE}/materialized/boosted-revenue`).then((data: any[]) => {
      data.forEach(processStatsData);
      return data;
    });
  },
  boostedRevenueStats({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): IBoostStats[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-boosted-revenue-live-usd-snowflake?${query}`).then((data: any[]) => {
      data.forEach(processStatsData);
      return data;
    });
  },
  reactivationStats({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): IReactivationStats[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-reactivation-live-usd-snowflake?${query}`).then((data: any[]) => {
      data.forEach(processStatsData);
      return data;
    });
  },
  surveyResponses({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): ISurveyResponseCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-survey-responses?${query}`).then((data: any[]) => {
      data.forEach((d) => {
        d.date = new Date(d.day);
      });
      return data;
    });
  },
  surveyResponseRates({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): ISurveyResponseRate[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-survey-response-rates-snowflake?${query}`).then((data: any[]) => {
      data.forEach((d) => {
        if (d.month) {
          d.date = new Date(d.month);
        }
        if (d.week) {
          // format 2023-29
          const [year, week] = d.week.split('-');
          d.date = timeWeek.offset(new Date(`${year}-01-01`), +week);
        }
      });
      return data;
    });
  },
  sentimentTrends({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): ISentimentTrend[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-sentiment-trends-snowflake?${query}`).then((data: any[]) => {
      data.forEach((d) => {
        if (d.month) {
          d.date = new Date(d.month);
        }
        if (d.week) {
          // format 2023-29
          const [year, week] = d.week.split('-');
          d.date = timeWeek.offset(new Date(`${year}-01-01`), +week);
        }
      });
      return data;
    });
  },
  sessionOutcomes({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): ISessionOutcomeCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-session-outcomes-snowflake?${query}`);
  },
  offerOutcomes({ filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }): ISessionOutcomeCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-offer-outcomes-snowflake?${query}`);
  },
  recentSaves({ filter, options }: { filter?: ISessionFilter; options?: { limit: number } } = {}): ISession[] {
    const query = filterBreakdownQuery({ filter: { [SESSION.ABORTED]: false, [SESSION.CANCELED]: false, [SESSION.BOUNCED]: false, ...filter } });

    return Axios.get(`${RESOURCE}/org-sessions?${query}&limit=${options?.limit || 10}`).then((data: any[]) => data.map(mapSessionDates));
  },
  sessionCount({ filter }: { filter?: ISessionFilter } = {}): number {
    const query = filterBreakdownQuery({ filter: { ...filter } });

    return Axios.get(`${RESOURCE}/org-sessions?${query}&count=1`);
  },
  filteredSessions({ filter, options }: { filter?: ISessionFilter; options?: { limit: number; skip: number } } = {}): ISession[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });

    return Axios.get(`${RESOURCE}/org-sessions?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}`).then((data: any[]) =>
      data.map(mapSessionDates)
    );
  },
  filteredFeedback({ filter, options }: { filter?: ISessionFilter; options?: { limit: number; skip: number } } = {}): ISentimentSession[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });

    return Axios.get(`${RESOURCE}/org-session-feedback?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}`).then((data: any[]) =>
      data.map(mapSessionDates)
    );
  },
  recentSessions({ filter, options }: { filter?: ISessionFilter; options?: { limit: number } } = {}): ISession[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });

    return Axios.get(`${RESOURCE}/org-sessions?${query}&limit=${options?.limit || 50}`).then((data: any[]) => data.map(mapSessionDates));
  },
  testSessions({ filter, options }: { filter?: ISessionFilter; options?: { limit: number } } = {}): ISession[] {
    const query = filterBreakdownQuery({ filter: { [SESSION.MODE]: 'TEST', ...filter } });

    return Axios.get(`${RESOURCE}/org-sessions?${query}&limit=${options?.limit || 50}`).then((data: any[]) => data.map(mapSessionDates));
  },
  sessionSearch({ search, filter, options }: { search?: string; filter?: ISessionFilter; options?: { limit: number } } = {}): IMatchedSession[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });

    return Axios.get(`${RESOURCE}/org-session-search?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&q=${search}`).then(
      (data: any[]) => data.map(mapSessionDates)
    );
  },
  sessionFilterValues({ filter }: { filter?: ISessionFilter } = { filter: {} }): ISessionFilterValues {
    const query = filterBreakdownQuery({ filter });

    return Axios.get(`${RESOURCE}/org-applicable-filters?${query}`).then((data: any) => {
      if (data.minDate) {
        data.minDate = new Date(data.minDate);
      }
      if (data.maxDate) {
        data.maxDate = new Date(data.maxDate);
      }
      if (data.trial) {
        if (data.trial.includes(true)) {
          data.trial = [true, false];
        }
      }
      if (data.ageYears) {
        data.ageYears = data.ageYears.filter((d: number) => d !== null).sort();
      }
      if (data.segmentId) {
        data.segmentId = ['null', ...data.segmentId];
      }
      return data;
    });
  },
  eventFilterOptions({ filter }: { filter?: IEventFilter } = { filter: {} }): IEventFilterOptions {
    const query = filterBreakdownQuery({ filter });

    return Axios.get(`${RESOURCE}/org-event-filters?${query}`).then((data: any) => {
      if (data.minDate) {
        data.minDate = new Date(data.minDate);
      }
      if (data.maxDate) {
        data.maxDate = new Date(data.maxDate);
      }
      return data;
    });
  },
  getCooldownProjections(): ICooldownProjection[] {
    const twoMonthsAgo = timeDay.offset(new Date(), -60);

    return Axios.get(`${RESOURCE}/org-sessions-count?breakdown=cooldownProjection&offerType=DISCOUNT&startDate=${twoMonthsAgo.toISOString()}`).then(
      (data: any) =>
        data
          // .filter((d: any) => d.cooldownProjection !== null)
          .sort((a: any, b: any) => a.cooldownProjection - b.cooldownProjection)
    );
  },
  fetchABSessionCounts(): any {
    return Axios.get(`${RESOURCE}/org-sessions-count-snowflake?breakdown=abtest&-abtest=null`).then((data: any) => {
      const dataMap = data.reduce((acc: any, d: any) => {
        acc[d.abtest] = d.count;
        return acc;
      }, {});
      return dataMap;
    });
  },
  invoiceCount({ filter, breakdown }: { filter?: any; breakdown?: string[] }): IInvoiceCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-invoice-count?${query}`);
  },
  fetchSegmentSessionCounts(): any {
    const thirtyDaysAgo = timeDay.floor(timeDay.offset(new Date(), -30));

    return Axios.get(`${RESOURCE}/org-sessions-count-snowflake?breakdown=segmentId&startDate=${thirtyDaysAgo.toISOString()}`).then((data: any) => {
      const dataMap = data.reduce((acc: any, d: any) => {
        if (d.segmentId) {
          acc[d.segmentId] = d.count;
        } else {
          acc.null = d.count;
        }
        return acc;
      }, {});
      return dataMap;
    });
  },
  /////////
  // DUNNING
  /////////
  dunningEmailCounts({ filter, breakdown }: { filter?: any; breakdown?: string[] } = { filter: {} }): IEmailCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });
    return Axios.get(`${RESOURCE}/org-dunning-email-count-snowflake?${query}`);
  },

  dunningEmailTimeline({ filter }: { filter?: any } = { filter: {} }): IEmailsByDate[] {
    // create days 30 days before and 30 days after today
    const startDate = timeDay.floor(timeDay.offset(new Date(), -30));
    const endDate = timeDay.ceil(timeDay.offset(new Date(), 30));
    const query = filterBreakdownQuery({ filter: { ...filter, startDate, endDate }, breakdown: [EMAIL.STATUS, EMAIL.SEND_DATE] });

    return Axios.get(`${RESOURCE}/org-dunning-email-count-snowflake?${query}`).then((data: any[]) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      data.map((d: any) => ({
        // send date (d.sendDate) is formatted 2023-12-25 but is in UTC time
        // add difference to utc time to get local time
        sendDate: timeMinute.offset(new Date(d.sendDate), new Date().getTimezoneOffset()),
        count: d.count,
        status: d.status,
      }))
    );
  },

  filteredDunningCampaigns({
    filter,
    options,
  }: { filter?: any; options?: { limit?: number; skip?: number; sort?: string; count?: boolean } } = {}): ICampaign[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });
    const sort = options?.sort || '-updatedAt';
    const querySort = sort.startsWith('-') ? `-sort=${sort.substring(1)}` : `sort=${sort}`;
    const queryCount = options?.count ? 'count=true' : '';

    return Axios.get(
      `${RESOURCE}/org-dunning-campaigns?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&${querySort}&${queryCount}`
    ).then((data: any[]) => data.map(processCampaign));
  },

  dunningCampaignCount({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): Promise<IDunningCampaignCount[]> {
    const query = filterBreakdownQuery({ filter, breakdown });
    return Axios.get<IDunningCampaignCount[]>(`${RESOURCE}/org-dunning-campaign-count-snowflake?${query}`);
  },

  dunningCampaignCountUsd({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): Promise<IDunningCampaignCount[]> {
    const query = filterBreakdownQuery({ filter, breakdown });
    return Axios.get<IDunningCampaignCount[]>(`${RESOURCE}/org-dunning-campaign-count-usd-snowflake?${query}`);
  },

  ///billing-metrics/timeseries-snowflake

  dunningTotalPaymentsDueCountUsd({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): IDunningTotalInvoicesDue[] {
    const query = filterBreakdownQuery({ filter, breakdown });
    // @ts-ignore
    return Axios.get(`${RESOURCE}/org-dunning-total-payment-due-snowflake?${query}`);
  },

  blockCounts({ filter, breakdown }: { filter?: any; breakdown?: string[] } = { filter: {} }): IBlockCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-dunning-block-count?${query}`);
  },

  /////////////////////////////////////////////
  // CHURN METRICS (BILLING METRICS)
  /////////////////////////////////////////////

  billingMetricsTimeSeries({ filter, breakdown }: { filter?: IBillingMetricsTimeSeriesFilter; breakdown?: string[] }): IBillingMetricsTimeSeries[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/billing-metrics/timeseries-snowflake?${query}`);
  },

  /// TO DO: Update types
  billingMetricsRetentionCohort({ filter, breakdown }: { filter?: any; breakdown?: string[] }): any[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/billing-metrics/retention-cohort-snowflake?${query}`);
  },

  billingMetricsRetainedRevenue({ filter, breakdown }: { filter?: any; breakdown?: string[] }): any[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/billing-metrics/retention-cake-snowflake?${query}`);
  },

  ///billing-metrics/timeseries-snowflake

  /////////////////////////////////////////////
  // REACTIVATIONS
  /////////////////////////////////////////////

  reactivationEmailTimeline({ filter }: { filter?: any } = { filter: {} }): IEmailsByDate[] {
    // create days 30 days before and 30 days after today
    const startDate = timeDay.floor(timeDay.offset(new Date(), -30));
    const endDate = timeDay.ceil(timeDay.offset(new Date(), 30));
    const query = filterBreakdownQuery({ filter: { ...filter, startDate, endDate }, breakdown: [EMAIL.STATUS, EMAIL.SEND_DATE] });

    return Axios.get(`${RESOURCE}/org-reactivation-email-count-snowflake?${query}`).then((data: any[]) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      data.map((d: any) => ({
        // send date (d.sendDate) is formatted 2023-12-25 but is in UTC time
        // add difference to utc time to get local time
        sendDate: timeMinute.offset(new Date(d.sendDate), new Date().getTimezoneOffset()),
        count: d.count,
        status: d.status,
      }))
    );
  },
  filteredReactivationCampaigns({
    filter,
    options,
  }: { filter?: any; options?: { limit?: number; skip?: number; sort?: string; count?: boolean } } = {}): Promise<ICampaign[]> {
    const query = filterBreakdownQuery({ filter: { ...filter } });
    const sort = options?.sort || '-updatedAt';
    const querySort = sort.startsWith('-') ? `-sort=${sort.substring(1)}` : `sort=${sort}`;
    const queryCount = options?.count ? 'count=true' : '';
    return Axios.get<ICampaign[]>(
      `${RESOURCE}/org-reactivation-campaigns?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&${querySort}&${queryCount}`
    ).then((data: any[]) => data.map(processCampaign) as ICampaign[]);
  },

  filteredReactivationEmails({
    filter,
    options,
  }: { filter?: any; options?: { limit?: number; skip?: number; sort?: string; count?: boolean } } = {}): Promise<IEmail[]> {
    const query = filterBreakdownQuery({ filter: { ...filter } });
    const sort = options?.sort || '-updatedAt';
    const querySort = sort.startsWith('-') ? `-sort=${sort.substring(1)}` : `sort=${sort}`;
    const queryCount = options?.count ? 'count=true' : '';
    return Axios.get<IEmail[]>(
      `${RESOURCE}/org-reactivation-emails?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&${querySort}&${queryCount}`
    ).then((data: any[]) => data.map(processEmail) as IEmail[]);
  },
  reactivationEmailCount({ filter, breakdown }: { filter?: any; breakdown?: string[] } = { filter: {} }): Promise<IReactivationEmailCount[]> {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get<IReactivationEmailCount[]>(`${RESOURCE}/org-reactivation-email-count-snowflake?${query}`);
  },

  reactivationCampaignEmailCount({ filter }: { filter?: any } = { filter: {} }): Promise<ICampaignEmailCount[]> {
    const query = filterBreakdownQuery({ filter });

    return Axios.get<ICampaignEmailCount[]>(`${RESOURCE}/org-reactivation-campaign-email-count-snowflake?${query}`);
  },

  reactivationBoostedRevenueUsd({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): IReactivationCampaignCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-boosted-reactivation-revenue-snowflake?${query}`);
  },

  reactivationCampaignCountUsd({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): IReactivationCampaignCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-reactivation-campaign-count-usd-snowflake?${query}`);
  },

  reactivationCampaignCount({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): IReactivationCampaignCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-reactivation-campaign-count-snowflake?${query}`);
  },

  reactivationCampaignCountLive({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): IDunningCampaignCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-reactivation-campaign-count?${query}`);
  },

  ////////////
  // EVENTS
  ////////////
  materalizedEventRollup({ filter, breakdown }: { filter?: any; breakdown?: string[] } = { filter: {} }): IEventRollup[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/materialized/event-rollup?${query}`).then((data: any) =>
      data.map((d: any) => ({ ...d, dateString: d.date, date: new Date(d.date) }))
    );
  },
  filteredEvents({
    filter,
    options,
  }: { filter?: IEventFilter; options?: { limit?: number; skip?: number; withRecords?: boolean; sort?: string } } = {}): IEvent[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });
    const sort = options?.sort || '-createdAt';
    const querySort = sort.startsWith('-') ? `-sort=${sort.substring(1)}` : `sort=${sort}`;
    const withRecords = options?.withRecords ? 'true' : 'false';

    return Axios.get(
      `${RESOURCE}/org-events?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&${querySort}&withRecords=${withRecords}`
    ).then((data: any[]) => data.map(processEventData));
  },
  countEvents({ filter, breakdown }: { filter?: IEventFilter; breakdown?: string[] }): any[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-event-count?${query}`);
  },
  filteredDataRecords({
    filter,
    options,
  }: { filter?: any; options?: { limit?: number; skip?: number; sort?: string; count?: boolean } } = {}): IDataRecord[] {
    const query = filterBreakdownQuery({ filter: { ...filter } });
    const sort = options?.sort || '-updatedAt';
    const querySort = sort.startsWith('-') ? `-sort=${sort.substring(1)}` : `sort=${sort}`;
    const queryCount = options?.count ? 'count=true' : '';

    return Axios.get(
      `${RESOURCE}/org-data-records?${query}&limit=${options?.limit || 50}&skip=${options?.skip || 0}&${querySort}&${queryCount}`
    ).then((data: any[]) => data.map(processCampaign));
  },

  reactivationBlockCounts({ filter, breakdown }: { filter?: any; breakdown?: string[] } = { filter: {} }): IBlockCount[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-reactivation-block-count?${query}`);
  },

  dataRecordCount({ filter, breakdown }: { filter?: IDunningCampaignFilter; breakdown?: string[] }): any[] {
    const query = filterBreakdownQuery({ filter, breakdown });

    return Axios.get(`${RESOURCE}/org-data-record-count?${query}`);
  },

  getCompanyHealth(): any {
    return Axios.get(`${HEALTH_RESOURCE}/company`).then((response: any) => {
      return response;
    });
  },
  listSubscriptionHealths({
    risk,
    billingInterval,
    plans,
    subscriptionAge,
    skip,
    limit,
  }: {
    risk: 'low' | 'medium' | 'high';
    billingInterval?: string[];
    plans?: string[];
    subscriptionAge?: number[];
    skip?: number;
    limit?: number;
  }): any {
    const url = `${HEALTH_RESOURCE}/subscriptions?`;
    const params = new URLSearchParams();
    params.append('risk', risk);

    if (billingInterval && billingInterval.length > 0) params.append('billingInterval', billingInterval.join(','));
    if (plans && plans.length > 0) params.append('plans', plans.join(','));
    if (subscriptionAge && subscriptionAge.length > 0) params.append('subscriptionAge', subscriptionAge.join(','));
    if (skip) params.append('skip', skip.toString());
    if (limit) params.append('limit', limit.toString());

    return Axios.get(`${url}${params.toString()}`).then((response: any) => {
      return response;
    });
  },
  exportSubscriptionHealths({
    risk,
    billingInterval,
    plans,
    subscriptionAge,
  }: {
    risk: 'low' | 'medium' | 'high';
    billingInterval?: string[];
    plans?: string[];
    subscriptionAge?: number[];
  }): any {
    const url = `${HEALTH_RESOURCE}/subscriptions-export?`;
    const params = new URLSearchParams();
    params.append('risk', risk);

    if (billingInterval && billingInterval.length > 0) params.append('billingInterval', billingInterval.join(','));
    if (plans && plans.length > 0) params.append('plans', plans.join(','));
    if (subscriptionAge && subscriptionAge.length > 0) params.append('subscriptionAge', subscriptionAge.join(','));

    return Axios.get(`${url}${params.toString()}`).then((response: any) => {
      return response;
    });
  },
};
