import Vue from 'vue';
import Vuex, { CommitOptions, DispatchOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import mutations from '@/store/mutations';
import actions from '@/store/actions';
import * as getters from '@/store/getters';
import DashboardModule from './modules/dashboard';
import DunningModule from './modules/dunning';
import DunningDashboardModule from './modules/dunning-dashboard';
import ReactivationModule from './modules/reactivation';
import SubscriptionAnalyticsModule from './modules/subscription-analytics';
import PermissionModule from './modules/permissions';
import AIModule from './modules/ai';
import { LOAD_STATE } from './shared';
import { Store } from 'vuex';
import { mapGetters as vuexMapGetters, mapState as vuexMapState } from 'vuex';
import { IState } from './types';
Vue.use(Vuex);

const storeConfig = {
  plugins: [
    createPersistedState({
      paths: ['token', 'authStatus', 'user', 'promo', 'asOrg', 'selectedPlanType', 'confidential', 'testMode'],
    }),
  ],
  state: {
    user: null,
    confidential: false,
    testMode: false,
    token: window.localStorage.getItem('token') || '',
    authStatus: null,
    twoFactorStatus: null,
    twoFactorErrorMessage: null,
    customerActivities: [],
    org: null,
    syncUserLoadState: LOAD_STATE.UNLOADED,
    syncOrgLoadState: LOAD_STATE.UNLOADED,
    // capture what the blueprint looks like in mongo
    blueprints: {},
    segments: {},
    webhooks: {},
    campaignBlueprints: {},
    campaignBlueprintEmailCounts: {},
    dunningExclusions: {},
    dunningExclusionBlockCounts: {},
    reactivationCampaignBlueprints: {},
    reactivationCampaignBlueprintEmailCounts: {},
    reactivationExclusions: {},
    currentBlueprintId: null,
    selectedTemplate: null,
    orgs: [],
    stripeData: {
      couponsLoadState: LOAD_STATE.UNLOADED,
      pricesLoadState: LOAD_STATE.UNLOADED,
    },
    promo: null,
    productIntent: null,
    asOrg: null,
    selectedPlanType: 'undecided',
    previewInline: false,
    previewMergeFields: {},
    issues: [],
    ...DashboardModule.state,
    ...DunningModule.state,
    ...DunningDashboardModule.state,
    ...ReactivationModule.state,
    ...SubscriptionAnalyticsModule.state,
    ...AIModule.state,
    ...PermissionModule.state,
  },
  actions: {
    ...actions,
    ...DashboardModule.actions,
    ...DunningModule.actions,
    ...DunningDashboardModule.actions,
    ...ReactivationModule.actions,
    ...SubscriptionAnalyticsModule.actions,
    ...AIModule.actions,
    ...PermissionModule.actions,
  },
  getters: {
    ...getters,
    ...DashboardModule.getters,
    ...DunningModule.getters,
    ...DunningDashboardModule.getters,
    ...ReactivationModule.getters, // order matters, Reactivation uses some Dunning getters
    ...SubscriptionAnalyticsModule.getters,
    ...AIModule.getters,
    ...PermissionModule.getters,
  },
  mutations: {
    ...mutations,
    ...DashboardModule.mutations,
    ...DunningModule.mutations,
    ...DunningDashboardModule.mutations,
    ...ReactivationModule.mutations,
    ...SubscriptionAnalyticsModule.mutations,
    ...AIModule.mutations,
    ...PermissionModule.mutations,
  },
};

export function getInitialState(): IState {
  return { ...storeConfig.state };
}

export type CStoreType = typeof storeConfig;

type FnToGetter<T> = {
  [K in keyof T]: T[K] extends (...v: any[]) => any ? ReturnType<T[K]> : never;
};

interface Dispatch {
  <K extends keyof CStoreType['actions']>(
    type: K,
    payload?: Parameters<CStoreType['actions'][K]>[1],
    options?: DispatchOptions
  ): ReturnType<CStoreType['actions'][K]>;
}

interface Commit {
  <K extends keyof CStoreType['mutations']>(
    type: K,
    payload?: Parameters<CStoreType['mutations'][K]>[1],
    options?: CommitOptions
  ): ReturnType<CStoreType['mutations'][K]>;
}

export interface CStore extends Store<IState> {
  getters: FnToGetter<CStoreType['getters']>;
  dispatch: Dispatch;
  commit: Commit;
}

declare module 'vue/types/vue' {
  interface Vue {
    $tsStore: CStore;
  }
}

const store = new Vuex.Store(storeConfig);
export default store;

export function mapGetters<Keys extends keyof CStore['getters']>(keys: Keys[]): { [K in Keys]: () => CStore['getters'][K] } {
  return vuexMapGetters(keys) as any;
}

export function mapState<Keys extends keyof CStore['state']>(keys: Keys[]): { [K in Keys]: () => CStore['state'][K] } {
  return vuexMapState(keys) as any;
}

export function useStore(): CStore {
  // Object.defineProperty(res, 'mapGetters', {
  //   value: function <Keys extends keyof CStore['getters']>(keys: Keys[]) {
  //     const map = {}
  //     keys.forEach((key) => {
  //       map[key] = computed(() => res.getters[key]())
  //     })

  //   },
  //   writable: false,
  //   enumerable: false,
  // })
  return store as CStore;
}
