/* eslint-disable camelcase */
import { Offer, OfferType } from '@/models/Offer';
export { OfferType };
import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import * as Vuex from 'vuex';
import { IStripeCoupon, IStripeCustomer, IStripePrice, IStripeProduct, StripeCouponDuration } from './stripe-types';

export type StepType = 'OFFER' | 'SURVEY' | 'CONFIRM' | 'FREEFORM';
// specific for UI flow
export type StepPlace = 'INITIAL_OFFER' | 'SURVEY' | 'CONFIRM' | 'FREEFORM' | 'FINAL_OFFER';
export type InvoiceStatusType = 'PAID' | 'DRAFT' | 'VOID' | 'OPEN';

export type CampaignOfferType = 'DISCOUNT' | 'INVOICE_DISCOUNT';
export type CampaignInvoiceDiscountType = 'AMOUNT' | 'PERCENT';
export type SaveType = 'PAUSE' | 'DISCOUNT' | 'CONTACT' | 'PLAN_CHANGE' | 'REDIRECT' | 'ABANDON' | 'TRIAL_EXTENSION' | 'PLAN_CHANGE';
export type DiscountType = 'AMOUNT' | 'PERCENT';
export type UserRole = 'SUPER_ADMIN' | 'SUPER_MEMBER' | 'SUPER_VIEWER' | 'ORG_OWNER' | 'ORG_ADMIN' | 'ORG_DEVELOPER' | 'ORG_MEMBER' | 'ORG_VIEWER';
export type CancelSchedule = 'IMMEDIATE' | 'PERIOD_END';
export type SurveyChoiceType = 'RADIO' | 'INPUT';
export type PauseInterval = 'MONTH' | 'WEEK';
export type SubscriptionInterval = 'MONTH' | 'YEAR' | 'WEEK' | 'DAY';
export type Mode = 'TEST' | 'LIVE';
export type SaveState = 'SAVED' | 'UNSAVED' | 'SAVING' | 'ERROR_SAVING';
export type LoadState = 'UNLOADED' | 'LOADED' | 'LOADING' | 'ERROR_LOADING';
export type SegmentAttribute =
  | 'CUSTOMER_EMAIL'
  | 'CUSTOMER_HAS_EMAIL'
  | 'CUSTOMER_HAS_PHONE'
  | 'PLAN_ID'
  | 'PRODUCT_ID'
  | 'CURRENCY'
  | 'PRICE'
  | 'BILLING_INTERVAL'
  | 'BILLING_INTERVAL_COUNT'
  | 'SUBSCRIPTION_AGE_MONTHS'
  | 'SUBSCRIPTION_START_DATE'
  | 'SUBSCRIPTION_STATUS'
  | 'SUBSCRIPTION_STATUS_ON_CANCEL'
  | 'SUBSCRIPTION_DISCOUNT'
  | 'CUSTOMER_COUNTRY'
  | 'INVOICE_CURRENCY'
  | 'INVOICE_AMOUNT_DUE'
  | 'SURVEY_CHOICE'
  | 'CANCEL_TYPE'
  | 'CANCEL_DATE'
  | 'FREEFORM_SENTIMENT';
export type SegmentOperand = 'EQUAL' | 'INCLUDES' | 'GT' | 'LT' | 'GTE' | 'LTE' | 'BETWEEN' | 'NOT_EQUAL' | 'NOT_INCLUDES' | 'NOT_BETWEEN';
export type SegmentedFeature = 'CANCEL_FLOW' | 'DUNNING' | 'REACTIVATION';
export type SubscriptionStatus = 'ACTIVE' | 'TRIALING' | 'CANCELED' | 'UNPAID' | 'INCOMPLETE' | 'INCOMPLETE_EXPIRED' | 'PAST_DUE';
export type SubscriptionDiscount = 'ONCE' | 'FOREVER' | 'REPEATING';
export type WebhookStatus = 'SOUND' | 'BROKEN' | 'UNVERIFIED';
export type CustomAttributeType = 'STRING' | 'NUMBER' | 'DATE' | 'BOOLEAN';
export type CustomDiscountDuration = 'ONCE' | 'RECURRING';
export type EventType = 'TRACK' | 'UPDATE';
export type RecordEntityType = 'USER' | 'CUSTOMER';
export type PaddleVersion = 'CLASSIC' | 'BILLING';
export type AIJobStatus =
  | 'PENDING' // not started yet
  | 'RUNNING' // generation in progress
  | 'READY' // generation is finished, outcome ready
  | 'FAILED' // error during generation
  | 'COMPLETED' // successful job was implemented into entity, or failed job was dismissed
  | 'ABORTED'; // aborted by user - placeholder; right now we do not allow to abort jobs
export type AIJobType = 'GEN_BLUEPRINT' | 'REWORK_BLUEPRINT' | 'REWORK_STEP' | 'REWORK_SURVEY_CHOICE';
export type AIMessageType = 'SYSTEM' | 'USER' | 'FUNCTION';
export type DunningMessageType = 'EMAIL' | 'SMS';

export interface IPublishableModel {
  publishedAt?: string;
  publishedCopies: this[];
  version?: number;
  guid: string;
  locked?: boolean;
}

export const enum EmailStatus {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  OPENED = 'opened',
  CLICKED = 'clicked',
  BOUNCED = 'bounced',
  DELIVERED = 'delivered',
  NOT_SENT = 'not_sent',
  RECOVERED = 'recovered',
  INACTIVE = 'inactive',
  SKIPPED = 'skipped',
}

export interface AsyncData {
  editable?: boolean;
  saveState?: SaveState;
  loadState?: LoadState;
  batchUpdateTimeout?: ReturnType<typeof setTimeout>;
}

// API update results
export interface DBOperationCounts {
  nInserted: number;
  nUpserted: number;
  nMatched: number;
  nModified: number;
  nRemoved: number;
}
export interface DBResultsByType {
  created: DBOperationCounts;
  deleted: DBOperationCounts;
  updated: DBOperationCounts;
}

///////////////
// SURVEY
///////////////

export interface ISurveyChoice {
  guid: string;
  value?: string;
  type: SurveyChoiceType;
  followup?: {
    question: string;
  };
  offer?: Offer;
}

export interface ISurvey {
  guid: string;
  randomize?: boolean;
  choices: ISurveyChoice[];
}

///////////////
// BLUEPRINT STEPS
///////////////

export interface IBlueprintBaseStep {
  guid: string;
  stepType: StepType;
  stepPlace?: StepPlace;
  enabled: boolean;
  header?: string;
  description?: string;
}

export interface IFreeformConfig {
  inputRequired: boolean;
  minLength: number;
}

export interface IConfirmConfig {
  requireAcknowledgement: boolean;
  discountNotice: boolean;
}

export interface IFreeformStep extends IBlueprintBaseStep {
  freeformConfig: IFreeformConfig;
}

export interface IConfirmStep extends IBlueprintBaseStep {
  confirmConfig: IConfirmConfig;
}

export interface ISurveyStep extends IBlueprintBaseStep {
  survey: ISurvey;
}

export interface IOfferStep extends IBlueprintBaseStep {
  offer?: Offer;
}

export function isIFreeformStep(step: IBlueprintBaseStep): step is IFreeformStep {
  return step.stepType === 'FREEFORM';
}

export function isIConfirmStep(step: IBlueprintBaseStep): step is IConfirmStep {
  return step.stepType === 'CONFIRM';
}

export function isISurveyStep(step: IBlueprintBaseStep): step is ISurveyStep {
  return step.stepType === 'SURVEY';
}

export function isIOfferStep(step: IBlueprintBaseStep): step is IOfferStep {
  return step.stepType === 'OFFER';
}

export type BlueprintStep = IFreeformStep | IConfirmStep | ISurveyStep | IOfferStep;

/*
export function canAIReworkStep(step: BlueprintStep): boolean {
  return step.stepType === 'OFFER' || step.stepType === 'CONFIRM';
}
*/

///////////////
// BLUEPRINT
///////////////

export interface IBlueprint extends AsyncData {
  _id: string;
  guid: string;
  name?: string;
  version?: number;
  schemaVersion?: string;
  brandImage?: string;
  primaryColor?: string;
  brandTheme?: string;
  confetti?: boolean;
  showWatermark?: boolean;
  org: string;
  steps: BlueprintStep[];
  deleted?: boolean;
  locked?: boolean;
  publishedAt?: Date;
  updatedAt?: Date;
  createdAt?: Date;
  segment?: string;
  isTemplate?: boolean;
  aiGenerated?: boolean;
  aiJobId?: string;
}

///////////////
// SESSION ACCEPTED OFFERS
///////////////

export interface ISessionBaseOffer {
  guid: string;
  offerType: OfferType;
}

export interface ISessionPauseOffer extends ISessionBaseOffer {
  pauseInterval: PauseInterval;
  pauseDuration: number;
}

export interface ISessionNewPlanOffer extends ISessionBaseOffer {
  newPlanId: string;
  newPlanPrice: number;
}

export interface ISessionRedirectOffer extends ISessionBaseOffer {
  redirectUrl: string;
}

export interface ISessionDiscountOffer extends ISessionBaseOffer {
  couponId: string;
  couponType: DiscountType;
  couponAmount: number;
  couponDuration: number;
}

export interface ISessionTrialExtensionOffer extends ISessionBaseOffer {
  trialExtensionDays: number;
}

export type ISessionContactOffer = ISessionBaseOffer;

export function isISessionPauseOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionPauseOffer {
  return sessionOffer.offerType === 'PAUSE';
}

export function isISessionNewPlanOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionNewPlanOffer {
  return sessionOffer.offerType === 'PLAN_CHANGE';
}

export function isISessionRedirectOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionRedirectOffer {
  return sessionOffer.offerType === 'REDIRECT';
}

export function isISessionDiscountOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionDiscountOffer {
  return sessionOffer.offerType === 'DISCOUNT';
}

export function isISessionContactOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionContactOffer {
  return sessionOffer.offerType === 'CONTACT';
}

export function isISessionTrialExtensionOffer(sessionOffer: ISessionBaseOffer): sessionOffer is ISessionTrialExtensionOffer {
  return sessionOffer.offerType === 'TRIAL_EXTENSION';
}

export type SessionOffer =
  | ISessionPauseOffer
  | ISessionNewPlanOffer
  | ISessionRedirectOffer
  | ISessionDiscountOffer
  | ISessionContactOffer
  | ISessionTrialExtensionOffer;

///////////////
// SESSIONS
///////////////

export interface ISessionCustomer {
  id: string;
  email?: string;
  // discount on customer
  discount: IDiscount;
  created: Date;
  subscriptionId?: string;
  subscriptionStart?: Date;
  subscriptionPeriodStart?: Date;
  subscriptionPeriodEnd?: Date;
  subscriptionDiscount?: IDiscount;
  currency?: string;
  planId?: string;
  planPrice: number;
  billingInterval?: SubscriptionInterval;
  billingIntervalCount?: number;
  onTrial?: boolean;
  trialStart?: Date;
  trialEnd?: Date;
  paused?: boolean;
  pauseEnd?: Date;
  subscriptionInterval?: SubscriptionInterval;
}

export interface ISearchHighlightText {
  value: string;
  type: 'text' | 'hit';
}

export interface ISearchHighlight {
  score: number;
  path: string;
  texts: ISearchHighlightText[];
}

// for searches
export interface IMatchedSession {
  _id: string;
  customer?: ISessionCustomer;
  acceptedOffer: SessionOffer;
  aborted?: boolean;
  canceled?: boolean;
  surveyChoiceValue?: string;
  feedback?: string;
  mode?: Mode;
  createdAt: Date;
  score: number;
  highlights: ISearchHighlight[];
}

export type FeedbackEmotion = 'GRATEFUL' | 'PRACTICAL' | 'ANGRY' | 'CONFUSED';
export type FreeformSentimentCategory = 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL' | 'NONE';

export type SentimentScores = {
  subjectiveA?: { emotional: number; reasonable: number };
  emotionA?: { grateful: number; practical: number; angry: number; confused: number };
  emotionB?: { grateful: number; practical: number; angry: number; confused: number };
  productA?: { positive: number; negative: number };
  productB?: { negative: number; recommend: number };
  productC?: { great: number; poor: number };
  productD?: { like: number; dislike: number; hate: number; love: number };
  reasonA?: { feature: number; budget: number; technical: number; ease: number; refund: number; need: number };
  reasonB?: { feature: number; budget: number; technical: number; ease: number; refund: number; need: number };
  reactivateA?: { negative: number; reactivate: number };
  reactivateB?: { negative: number; reactivate: number };
};

export interface ISession {
  _id: string;
  org: string;
  blueprintId: string;
  customer?: ISessionCustomer;
  acceptedOffer: SessionOffer;
  recording?: string;
  recordingStartTime?: Date;
  recordingEndTime?: Date;
  aborted?: boolean;
  canceled?: boolean;
  surveyId?: string;
  surveyChoiceId?: string;
  surveyChoiceValue?: string;
  feedback?: string;
  sentimentScores?: SentimentScores;
  followupResponse?: string;
  followupQuestion?: string;
  mode?: Mode;
  createdAt: Date;
  updatedAt: Date;
  segmentId: string;
  bounced?: boolean;
  customActionHandler?: string;
}

export interface IABTestModalProp {
  name: string;
  winner: string;
  segmentId: string;
  selectedABTest: IABTest;
}

export interface IOfferPresented {
  accepted: boolean;
  offer: SessionOffer;
}

export interface IDecoratedSession extends ISession {
  surveyResponse: string | null;
  offersPresented: IOfferPresented[];
  paused: boolean;
}

export interface IComputedSentimentScores {
  grateful?: number;
  feature?: number;
  practical?: number;
  angry?: number;
  refund?: number;
  sentiment?: number;
  angryPractical?: number;
}

export interface ISentimentSession extends ISession {
  // ensemble scores
  eScores: IComputedSentimentScores;
}

// for dynamic breakdowns of stats
export interface ISessionBreakdown {
  trial?: boolean;
  mode?: Mode;
  segmentId?: string;
  response?: string;
  aborted?: boolean;
  canceled?: boolean;
  pauseDuration?: number;
  couponId?: string;
  planId?: string;
  billingInterval?: SubscriptionInterval | null;
  offerType?: OfferType;
  saveType?: SaveType;
  day?: string;
  week?: string;
  month?: string;
  // bin start and end date of stat
  startDate?: Date;
  endDate?: Date;
  invoiceMonth?: string;
  // binned start and end date, not invoice month
  invoiceStartDate?: Date;
  invoiceEndDate?: Date;
  bounced?: boolean;
  ageMonths?: number | null;
  ageQuarters?: number | null;
  ageYears?: number | null;
  orgId?: string;
}

export interface ICouponStats {
  orgId: string;
  orgName: string;
  acceptanceRate: number;
  couponAmount: number;
  couponDuration: number;
  couponType: string;
  timesAccepted: number;
  timesOffered: number;
}

export interface IPauseStats {
  orgId: string;
  orgName: string;
  acceptanceRate: number;
  timesAccepted: number;
  timesOffered: number;
  '0-3 - accepted': number;
  '0-3 - offered': number;
  '0-3 - rate': number;
  '18 - rate': number;
  '3-6 - rate': number;
  '6-12 - rate': number;
  '12-18 - rate': number;
  '18 - offered': number;
  '18 - accepted': number;
  '3-6 - offered': number;
  '3-6 - accepted': number;
  '6-12 - offered': number;
  '12-18 - offered': number;
  '6-12 - accepted': number;
  '12-18 - accepted': number;
}

export interface IBoostStats extends ISessionBreakdown {
  boostedRevenue: number;
  invoiceStartDate: Date;
  invoiceEndDate: Date;
  currencyBreakdown: Record<string, { units: number; unitsDecimal: number; usd: number }>;
}

export interface IReactivationStats extends ISessionBreakdown {
  total: number;
  reactivated: number;
  reactivationRate: number;
  boostedRevenue: number;
}

export interface ISurveyResponseCount extends ISessionBreakdown {
  count: number;
  response: string;
  date: Date;
}

export interface ISurveyResponseRate extends ISessionBreakdown {
  appeared: number;
  selected: number;
  selectedRate: number;
  surveyChoiceOption: string;
  date: Date;
}

export interface ISentimentTrend extends ISessionBreakdown {
  sentimentScore: number;
  date: Date;
  count: number;
}

export interface ISessionOutcomeCount extends ISessionBaseOffer {
  count: number;
  aborted: boolean;
  canceled: boolean;
  offerType: OfferType;
  couponId?: string;
  pauseDuration?: number;
  trialExtensionDays?: number;
  trial?: boolean;
}

export interface IOfferOutcomeCount extends ISessionBreakdown {
  accepted: number;
  rejected: number;
  acceptanceRate: number;
  offerType: OfferType;
  couponId?: string;
}

export interface ISessionFilterValues {
  count: number;
  trial: boolean[];
  response: string[];
  pauseDuration: number[];
  couponId: string[];
  billingInterval: SubscriptionInterval[];
  planId: string[];
  offerType: OfferType[];
  segmentId: string[];
  minDate: Date;
  maxDate: Date;
}

export interface ISessionFilter {
  aborted?: boolean;
  canceled?: boolean;
  response?: string;
  startDate?: Date;
  endDate?: Date;
  offerType?: string;
  billingInterval?: string;
  planId?: string;
  segmentId?: string;
  // of accepted offers
  couponId?: string;
  pauseDuration?: string;
  // other
  limit?: number;
  email?: string;
}

export interface IDataRecord {
  _id: string;
  orgId: string;
  uid: string;
  groupUid: string;
  entityType: RecordEntityType;
  data: Record<string, any>;
  dataUpdated?: Record<string, Date>;
  createdAt: Date;
  updatedAt: Date;
}

export interface IRecordFilter {
  startDate?: Date;
  endDate?: Date;
  entityType?: string;
  uid: string;
  dataItem?: string;
}

export interface IEvent {
  _id: string;
  eventType: EventType;
  orgId: string;
  uid?: string;
  customerId?: string;
  verified?: boolean;
  userUid?: string;
  eventData?: Record<string, any>;
  userData?: Record<string, any>;
  customerData?: Record<string, any>;
  createdAt: Date;
  updatedAt: Date;
  customerRecord?: IDataRecord;
  userRecord?: IDataRecord;
}

export enum TimelineType {
  SESSION = 'session',
  INVOICE = 'invoice',
  EVENT = 'event',
  EMAIL = 'email',
}

export interface IEventFilterOptions {
  event: string[];
  customerId: string[];
  uid: string[];
  userUid: string[];
  customerName: string[];
  customerEmail: string[];
  minDate: Date;
  maxDate: Date;
}

export interface ICompanyHealthFilterOptions {
  billingInterval: SubscriptionInterval[];
  plans: string[];
  subscriptionAge: number[];
}

export interface IEventFilter {
  startDate?: Date;
  endDate?: Date;
  event?: string;
  customerId?: string;
  uid?: string;
  userUid?: string;
  verified?: boolean;
  eventType?: string;
  customerName?: string;
  customerEmail?: string;
  userName?: string;
  userEmail?: string;
}

export interface IDunningCampaignFilter {
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  reactivated?: boolean;
  recovered?: boolean;
  trial?: boolean;
  failReason?: string;
  invoiceMonth?: string;
  invoiceDay?: string;
  invoiceWeek?: string;
  inherited?: boolean;
  segmentId?: string;
  blueprintId?: string;
  '-inherited'?: boolean;
  '-active'?: boolean;
  '-trial'?: boolean;
  campaignBlueprintId?: string;
}

export interface IDunningCampaignCount extends IDunningCampaignFilter {
  count: number;
  recoveredCount: number;
  invoiceAmount: number;
  recoveredAmount: number;
  invoiceAmountUsd: number;
  recoveredAmountUsd: number;
  pendingAmountUsd: number;
  lostAmountUsd: number;
  outcome: string;
  trendDataTimeInterval?: IDunningCampaignFilter['invoiceMonth'] | IDunningCampaignFilter['invoiceWeek'] | IDunningCampaignFilter['invoiceDay'];
  currencyBreakdown: Record<
    string,
    {
      invoiceAmountUsd: number;
      recoveredAmountUsd: number;
      pendingAmountUsd: number;
      lostAmountUsd: number;
    }
  >;
}

export interface IDunningTotalInvoicesDue {
  startDate?: Date;
  endDate?: Date;
  invoiceMonth?: string;
  invoiceDay?: string;
  invoiceWeek?: string;
  trendDataTimeInterval?: IDunningCampaignFilter['invoiceMonth'] | IDunningCampaignFilter['invoiceWeek'] | IDunningCampaignFilter['invoiceDay'];
  count: number;
  invoiceTotal: number;
  invoiceTotalUsd: number;
  totalSuccessfulInitiallyUsd: number;
  totalSuccessfulInitiallyCount: number;
  totalRecoveredUsd: number;
  totalRecoveredCount: number;
  activelyRecoveringUsd: number;
  activelyRecoveringCount: number;
  unrecoveredAmountUsd: number;
  unrecoveredCount: number;
}

export type IDunningTotalInvoicesDueKeys = keyof IDunningTotalInvoicesDue;

export interface IReactivationCampaignCount extends IDunningCampaignFilter {
  count: number;
  recoveredAmount: number;
  subscriptionCount: number;
  recoveredAmountUsd: number;
  averageRecoveryPerCampaignUsd: number;
  pendingCount: number;
  pendingAmountUsd: number;
  outcome: string;
  currencyBreakdown: Record<
    string,
    {
      invoiceAmountUsd: number;
      recoveredAmountUsd: number;
      pendingAmountUsd: number;
      lostAmountUsd: number;
    }
  >;
}

export enum TIME_INTERVAL {
  MONTH = 'Month',
  DAY = 'Day',
  WEEK = 'Week',
}

export const IDunningCampaignTimeIntervalGroupByMappingPrefix = 'invoice';

export const TIME_INTERVAL_LABEL = {
  [TIME_INTERVAL.MONTH]: 'Month',
  [TIME_INTERVAL.DAY]: 'Day',
  [TIME_INTERVAL.WEEK]: 'Week',
};

export type TREND_TIME_INTERVAL = TIME_INTERVAL.MONTH | TIME_INTERVAL.DAY | TIME_INTERVAL.WEEK;

// "count": 2,
// "countPaid": 2,
// "total": 2299,
// "subtotal": 2299,
// "amountPaid": 2299,
// "amountDue": 2299,
// "amountRemaining": 0,
// "invoiceHour": "2023-04-14-12",
// "paidAverage": 1150,
// "totalAverage": 1150
export interface IInvoiceCount {
  count: number;
  countPaid: number;
  total: number;
  subtotal: number;
  amountPaid: number;
  amountDue: number;
  amountRemaining: number;
  paidAverage: number;
  totalAverage: number;
  invoiceHour?: string;
  invoiceMonth?: string;
  invoiceDay?: string;
}

export type FilterOption = {
  label: string;
  value: string;
};

export type OptionsConfig = BaseFilterConfig & {
  options: FilterOption[];
  defaultValue: string | string[];
};

export type BaseFilterConfig = {
  label: string;
};

export interface IFilterConfig extends BaseFilterConfig {
  type?: 'date' | 'string[]' | 'boolean[]' | 'number[]';
  optionToLabel?: (d: any, context: { state: IState; getters: any }) => string;
}

export interface ICooldownProjection {
  count: number;
  cooldownProjection: number;
}

///////////////
// ORG
///////////////

export interface IPlanSummary {
  name: string;
  amount: number;
  description: string;
  interval: 'month' | 'year';
  metered?: boolean;
  lite?: boolean;
}

// for coupons applied as discounts (as opposed to general org coupons ICoupon)
export interface IDiscountCoupon {
  id: string;
  couponType: DiscountType;
  couponAmount: number;
  couponDuration: number | null;
  currency?: string | null;
}

export interface IDiscount {
  id: string;
  coupon: IDiscountCoupon;
  start: Date;
  end: Date;
}

export interface ICoupon {
  discountType: DiscountType;
  id: string;
  amountOff: number | null;
  percentOff: number | null;
  name: string | null;
  duration: StripeCouponDuration;
  months: number | null;
  maxRedemptions: number | null;
  valid: boolean;
  redeemBy: Date | null;
  created: Date | null;
  currency?: string | null;
}

export interface ISlackConnection {
  access_token: string;
  app_id: string;
  authed_user: {
    id: string;
  };
  bot_user_id: string;
  incoming_webhook: {
    channel: string;
    channel_id: string;
    configuration_url: string;
    url: string;
  };
  scope: string;
  team: {
    id: string;
    name: string;
  };
}

export interface ISlackNotifications {
  cancel: boolean;
  abort: boolean;
  discount: boolean;
  pause: boolean;
}

export interface INotifications {
  slack: {
    cancel: boolean;
    abort: boolean;
    discount: boolean;
    pause: boolean;
  };
  email: {
    weekly: boolean;
    monthly: boolean;
  };
}

///////////////
// DUNNING
///////////////

export interface ICustomDomain {
  url: string;
  isResolving: boolean;
  hasSSL: boolean;
  status: string;
  lastChecked: Date;
}

export interface ISenderDomain {
  url: string;
  dnsHostname: string;
  dnsValue: string;
  returnPathVerified: boolean;
  dkimVerified: boolean;
}

// From postmark npm package, in dist/client/models/domains/Domain.d.ts
export interface IDomainDetails {
  ID: number;
  Name: string;
  SPFVerified: boolean;
  DKIMVerified: boolean;
  WeakDKIM: boolean;
  ReturnPathDomainVerified: boolean;
  SPFHost: string;
  SPFTextValue: string;
  DKIMHost: string;
  DKIMTextValue: string;
  DKIMPendingHost: string;
  DKIMPendingTextValue: string;
  DKIMRevokedHost: string;
  DKIMRevokedTextValue: string;
  SafeToRemoveRevokedKeyFromDNS: string;
  DKIMUpdateStatus: string;
  ReturnPathDomain: string;
  ReturnPathDomainCNAMEValue: string;
}

export interface IDunning {
  enabled?: boolean;
  smsEnabled?: boolean;
  invoicesToRecover?: number;
  recoveryPageType?: string;
  activeCampaign?: string;
  exclusions?: string[];
  campaignBlueprints?: string[];
  senderDomain?: ISenderDomain;
  senderDomains?: ISenderDomain[];
  bcc?: string[];
  checkoutPage?: {
    ckSubdomain: string;
    customDomain: ICustomDomain;
    customDomains: ICustomDomain[];
    redirectLink: string;
    // TODO: add support for branding colors, support link, etc...
  };
}

export interface IPrecisionRetryConfig {
  enabled: boolean;
  strategy: string;
}

export interface IReactivation {
  oneTimeEnabled?: boolean;
  enabled?: boolean;
  bcc?: string[];
  activeCampaign?: string;
  campaignBlueprints?: string[];
  exclusions?: string[];
}

///////////////
// CAMPAIGN BLUEPRINT MESSAGE
///////////////

export interface IBaseCampaignOffer {
  guid: string;
  offerType: CampaignOfferType;
  header?: string;
  description?: string;
}

export interface ICampaignDiscountConfig {
  couponId: string;
}

export interface ICampaignInvoiceDiscountConfig {
  type: DiscountType;
  amount: number | null;
}

export interface ICampaignDiscountOffer extends IBaseCampaignOffer {
  discountConfig: ICampaignDiscountConfig;
}

export interface ICampaignInvoiceDiscountOffer extends IBaseCampaignOffer {
  invoiceDiscountConfig: ICampaignInvoiceDiscountConfig;
}

export type CampaignOffer = ICampaignDiscountOffer | ICampaignInvoiceDiscountOffer;

export function isICampaignDiscountOffer(offer: IBaseCampaignOffer): offer is ICampaignDiscountOffer {
  if (!offer || !offer.offerType) return false;
  return offer.offerType === 'DISCOUNT';
}

export function isICampaignInvoiceDiscountOffer(offer: IBaseCampaignOffer): offer is ICampaignInvoiceDiscountOffer {
  if (!offer || !offer.offerType) return false;
  return offer.offerType === 'INVOICE_DISCOUNT';
}

export interface IBaseMessage extends AsyncData {
  _id?: string;
  guid: string;
  enabled: boolean;
  delete?: boolean;
  content?: string;
  sendOnDay?: number;
  timeToSend?: string;
  offers?: CampaignOffer[];
}

export interface IEmailBlueprint extends IBaseMessage {
  sendOnDate?: Date; // only used in one-time reactivations
  from?: string;
  replyTo?: string;
  subject?: string;
  senderName?: string;
  previewText?: string;
  link?: string;
  templateId?: string;
  defaultTemplate?: string;
  mergeFields?: any;
  ctaText?: string;
  autoRetry?: boolean;
}

export type ISmsBlueprint = IBaseMessage;

// Helper interface for the Campaign Editor / Campaign Sequence / Email Editor
export interface IDunningMessageGroup extends AsyncData {
  // Sections
  guid: string;
  enableEmail?: boolean;
  enableSms?: boolean;
  delete?: boolean;
  // Data
  email: IEmailBlueprint;
  sms: ISmsBlueprint;
}

export type EmailMergeField = {
  id: string;
  label: string;
  fallback: string;
};

export type CancelFlowMergeField = {
  id: string;
  label: string;
  fallback: string;
};

///////////////
// COMMON AB TEST
///////////////

export interface ICommonABTest {
  _id: string;
  name?: string;
  variants: string[];
  variantModel: string;
  enabled: boolean;
  createdAt: Date;
  startedAt?: Date;
  completedAt?: Date;
  winner?: string;
}

///////////////
// AUDIENCE
///////////////

export interface IAudience {
  name: string;
  filters: ISegmentFilter[];
}

///////////////
// EXCLUSION
///////////////

export interface IExclusion extends IAudience, AsyncData, IPublishableModel {
  _id: string;
  enabled: boolean;
  deleted?: boolean;
  updatedAt?: string;
  createdAt?: string;
}

///////////////
// CAMPAIGN BLUEPRINT
///////////////

export type CampaignType = 'DELINQUENCY' | 'RENEWAL' | 'EXPIRATION' | 'REACTIVATION';
export type CampaignSchedule = 'RECURRING' | 'ONE_TIME';
export enum CampaignBlueprintState {
  DISABLED = 'DISABLED',
  SETUP = 'SETUP',
  COLLECTING = 'COLLECTING',
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  SENDING = 'SENDING',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export interface ICampaignBlueprint extends AsyncData, IPublishableModel {
  _id: string;
  enabled: boolean;
  deleted?: boolean;
  schemaVersion?: string;
  audience?: IAudience;
  campaignType?: CampaignType;
  campaignSchedule?: CampaignSchedule;
  emails: IEmailBlueprint[];
  sms?: ISmsBlueprint[];
  updatedAt?: string;
  createdAt?: string;
  abTests?: ICommonABTest[];
  state?: CampaignBlueprintState;
  deliveryStart?: Date;
  deliveryEnd?: Date;
}

///////////////
// CAMPAIGN
///////////////
export interface ICampaign {
  _id: string;
  created: Date;
  customerId: string;
  customerEmail: string;
  campaignType?: CampaignType;
  campaignSchedule?: CampaignSchedule;
  paymentProcessor: string;
  isActive: boolean;
  skipEmails: boolean;
  paymentAttempts: number;
  emails: string[];
  failedAfterTrial?: boolean;
  inherited?: boolean;
  initialDeclineReason?: string;
  recoveredFromRetry?: boolean;
  recoverDate?: Date;
  amountRecovered?: number;
  reasonInactive?: string;
  invoice: {
    amountDue: number;
    amountPaid: number;
    amountRemaining: number;
    billingReason: string;
    collectionMethod: string;
    created: string;
    currency: string;
    customer: string;
    customerEmail: string;
    dbCreatedAt: string;
    dbUpdatedAt: string;
    invoiceId: string;
    itemPeriodEnd: string;
    itemPeriodStart: string;
    orgId: string;
    orgName: string;
    paymentProvider: string;
    periodEnd: string;
    periodStart: string;
    status: string;
    subscriptionId: string;
    subtotal: number;
    total: number;
  };
  subscription: {
    currency: string;
    plan: {
      amount: number;
      currency: string;
      name: string;
    };
  };
  recoveredViaAutoRetry?: boolean;
  recoveredViaEmail?: boolean;
  recoveredViaSMS?: boolean;
  recoveredViaFailedPaymentWall?: boolean;
  recoveredViaRetryStrategy?: boolean;
  reactivateDate?: Date;
  amountReactivated?: number;
  failedPayment?: any;
}

///////////////
// EMAIL
///////////////
export interface IEmail {
  _id: string;
  campaignId: string;
  sendDate: Date;
  emailTo: string;
  isSent: boolean;
  isClicked: boolean;
  isOpened: boolean;
  isDelivered: boolean;
  isBounced: boolean;
  isRecovered: boolean;
  isSkipped: boolean;
  openDate?: Date;
  recoverDate?: Date;
  clickDate?: Date;
  bounceDate?: Date;
  from: string;
  subject: string;
  replyTo: string;
  previewText: string;
  content: string;
  templateId: string;
  status?: EmailStatus;
  statusConfig?: { name: EmailStatus; color: string; label: string };
  offers?: CampaignOffer[];
  ctaText: string;
}

/// Customer Activity

export interface BaseCustomerActivity {
  timelineDate: Date;
  timelineType: TimelineType;
}
export interface EventActivity extends BaseCustomerActivity {
  timelineType: TimelineType.EVENT;
  event: string;
  eventData: Record<string, any>;
}

export interface InvoiceActivity extends BaseCustomerActivity {
  timelineType: TimelineType.INVOICE;
  status: InvoiceStatusType;
  invoice: string;
  amountPaid: number;
  amountDue: number;
  currency: string;
  hosted_url: string;
}

export interface EmailActivity extends BaseCustomerActivity, IEmail {
  timelineType: TimelineType.EMAIL;
  campaign?: {
    campaignType: string;
  };
}

export interface SessionActivity extends BaseCustomerActivity {
  timelineType: TimelineType.SESSION;
  canceled: boolean;
  acceptedOffer?: {
    offerType: OfferType;
  };
  aborted: boolean;
  surveyChoiceValue?: string;
  followupResponse?: string;
  feedback?: string;
}

export type CustomerActivity = EventActivity | InvoiceActivity | EmailActivity | SessionActivity;

///////////////
// SEGMENT
///////////////
export type SegmentFilterValue = string[] | number[] | Date[] | null[] | string;

export interface ISegmentFilter {
  attribute?: SegmentAttribute | string;
  operand?: SegmentOperand;
  value?: SegmentFilterValue;
  type?: CustomAttributeType;
}

export interface IABTest {
  _id: string;
  name?: string;
  segments: string[];
  enabled: boolean;
  createdAt: Date;
  startedAt?: Date;
  completedAt?: Date;
  winner?: string;
}

export interface ISegment extends AsyncData {
  _id: string;
  name?: string;
  schemaVersion?: string;
  activeBlueprint?: string;
  blueprints: string[];
  filter?: ISegmentFilter[];
  enabled?: boolean;
  deleted?: boolean;
}

export interface IIssue {
  _id: string;
  org: string;
  issueData: any;
  resolvedAt: Date;
  lastSeenAt: Date;
  createdAt: Date;
  updatedAt: Date;
  muted?: boolean;
}

export type IssueUpdate = {
  _id: string;
  status?: 'resolved' | 'unresolved';
  muted?: boolean;
};

export enum TaskStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export interface ITaskItem {
  status: TaskStatus;
  percentage?: number;
  title?: string;
  description?: string;
}

export interface ITask {
  _id: string;
  org: string;
  taskType: string;
  blocking: boolean;
  status: TaskStatus;
  createdAt: Date;
  updatedAt: Date;
  percentage?: number;
  items?: ITaskItem[];
  title?: string;
  description?: string;
}

export interface IWebhook extends AsyncData {
  _id: string;
  url?: string;
  status?: WebhookStatus;
  enabled?: boolean;
  deleted?: boolean;
}

export interface ICustomAttribute {
  label: string;
  name: string;
  type: CustomAttributeType;
}

export interface IEmailFlow {
  enabled?: boolean;
  senderName?: string;
  from?: string;
  replyTo?: string;
  subject?: string;
}

export interface IOrg extends AsyncData {
  _id: string;
  appId: string;
  stripeCustomerId: string;
  members: string[];
  customerHealthEnabled: boolean;
  dataApiEnabled: boolean;
  feedbackAIEnabled: boolean;
  churnMetricsEnabled: boolean;
  apiUrl: string;
  blueprints: string[];
  segments: string[];
  abTests?: IABTest[];
  customAttributes?: ICustomAttribute[];
  aiJobs?: IAIJob[];
  providerSettings?: {
    planChangeProrate?: boolean;
    planChangeImmediate?: boolean;
    pauseEndOfTerm?: boolean;
    annualPauseExtendTerm?: boolean;
    cancelImmediate?: boolean;
    unpauseOnCancel?: boolean;
  };
  activeBlueprint?: string;
  webhooks?: string[];
  name?: string;
  chargebeeVersion?: string;
  paddleVersion?: PaddleVersion;
  braintreeAuthorized?: boolean;
  chargebeeAuthorized?: boolean;
  paddleAuthorized?: boolean;
  stripeAuthorized?: boolean;
  stripeAccountId?: string;
  secrets?: {
    stripeAccountId?: string;
    prod: {
      stripeApiKey?: string;
      stripeAccessToken?: string;
      stripeRefreshToken?: string;
      braintreeMerchantId?: string;
      braintreePublicKey?: string;
      braintreePrivateKey?: string;
      chargebeeSite?: string;
      chargebeeApiKey?: string;
      paddleVendorId?: string;
      paddleAuthCode?: string;
      paddlePublicKey?: string;
      paddleSecret?: string;
      paddleWebhookSecret?: string;
    };
    test: {
      stringApiKey?: string;
      stripeAccessToken?: string;
      stripeRefreshToken?: string;
      braintreeMerchantId?: string;
      braintreePublicKey?: string;
      braintreePrivateKey?: string;
      chargebeeSite?: string;
      chargebeeApiKey?: string;
      paddleVendorId?: string;
      paddleAuthCode?: string;
      paddlePublicKey?: string;
      paddleSecret?: string;
      paddleWebhookSecret?: string;
    };
    webhookSecret?: string;
  };
  branding?: {
    primaryColor?: string;
    brandImage?: string;
    brandTheme?: string;
    confetti?: boolean;
    showWatermark?: boolean;
  };
  dunningWaitlist: boolean;
  dunningBeta: boolean;
  discountCooldown?: number | null;
  smartRetries?: IPrecisionRetryConfig;
  pauseCooldown?: number | null;
  dunning?: IDunning;
  reactivation?: IReactivation;
  reactivationWaitlist: boolean;
  reactivationBeta: boolean;
  industry?: string;
  churnGoal?: number;
  // stripe account client has with Churnkey
  stripe?: IStripeCustomer;
  slack?: ISlackConnection;
  notifications?: INotifications;
  paymentProvider?: string;
  createdAt: string;
  plans?: Array<any>;
  translations?: Record<string, Record<string, string>>;
  fastTrackData?: any;
  fastTrackToken?: string;
  emailFlow?: IEmailFlow;
  populatedSegments?: ISegment[];
  populatedBlueprints?: IBlueprint[];
  require2FA?: boolean;
}

///////////////
// USER
///////////////

export interface IUser {
  _id: string;
  name: string;
  token?: string;
  email: string;
  role: UserRole;
  org: string;
  emailConfirmed?: boolean;
  developerNotifications?: boolean;
}

///////////////
// ORG ANALYTICS
///////////////
export interface IAnalyticsSnapshot {
  orgName: string;
  startDate: Date;
  endDate: Date;
  arpu: number;
  arr: number;
  avgLife: number;
  churnRate: number;
  cohortActive: number;
  currentSubs: number;
  newSubs: number;
  lostSubs: number;
  mrr: number;
  prevMrr: number;
}

///////////////
// DUNNING ANALYTICS
///////////////

export interface IEmailCount {
  count: number;
  invoiceAmount: number;
  invoiceAmountUsd: number;
  status?: string;
  emailBlueprintId?: string;
  campaignBlueprintId?: string;
  isSent?: string;
  isDelivered?: string;
  isClicked?: string;
  isBounced?: string;
  isOpened?: string;
  sendDate?: string;
  sendDateMonth?: string;
  sendDateWeek?: string;
  sendDateDay?: string;
}

export const IEmailCountTimeIntervalGroupByMappingPrefix = 'sendDate';

export interface IEmailStatusCount {
  sent: number;
  clicked: number;
  opened: number;
  recovered: number;
  bounced: number;
  scheduled: number;
}

export interface IReactivationEmailCount {
  count: number;
  status?: string;
  emailBlueprintId?: string;
  campaignBlueprintId?: string;
  isSent?: string;
  isDelivered?: string;
  isClicked?: string;
  isBounced?: string;
  isOpened?: string;
  sendDate?: string;
  sendDateMonth?: string;
}

export interface ICampaignEmailCount {
  count: number;
  campaignEmailStatus: 'recovered' | 'sent' | 'scheduled' | 'opened' | 'clicked' | 'bounced';
  campaignBlueprintId?: string;
}

export interface IBlockCount {
  count: number;
  exclusionId?: string;
}

////////////////////////////////////////////////////////////
// CHURN METRICS (BILLING METRICS)
////////////////////////////////////////////////////////////

export interface IBillingMetricsTimeSeriesFilter {
  startDate?: string;
  endDate?: string;
}

export interface IBillingMetricsTimeSeries extends IBillingMetricsTimeSeriesFilter {
  churnedMrr: number;
  churnedSubscriptions: number;
  contractionMrr: number;
  endActiveSubscriptions: number;
  endAverageRevenuePerSubscription: number;
  endMrr: number;
  expansionMrr: number;
  involuntaryChurnedSubscriptions: number;
  involuntaryChurnMrr: number;
  netMrrChange: number;
  netSubscriptionCountChange: number;
  newMrr: number;
  newSubscriptions: number;
  startActiveSubscriptions: number;
  startAverageRevenuePerSubscription: number;
  startMrr: number;
  voluntaryChurnedSubscriptions: number;
  voluntaryChurnMrr: number;
}

///////////////
// STORE STATE
///////////////

export interface IDashboardModuleState {
  analyticsSnapshotsLoadState: LoadState;
  analyticsSnapshots: IAnalyticsSnapshot[];
  boostedRevenueStats: IBoostStats[];
  boostedRevenueStatsLoadState: LoadState;
  couponStats: ICouponStats[];
  couponStatsLoadState: LoadState;
  pauseStats: IPauseStats | null;
  pauseStatsLoadState: LoadState;
  reactivationStats: IReactivationStats[];
  reactivationStatsLoadState: LoadState;
  dashboardDateRange: [Date, Date];
  sessions: ISession[];
  sessionsLoadState: LoadState;
  recentSaves: ISession[];
  recentSavesLoadState: LoadState;
  recentSessions: ISession[];
  recentSessionsLoadState: LoadState;
  testSessions: ISession[];
  testSessionsLoadState: LoadState;
  surveyResponses: ISurveyResponseCount[];
  surveyResponsesLoadState: LoadState;
  sessionOutcomes: ISessionOutcomeCount[];
  sessionOutcomesLoadState: LoadState;
  offerOutcomes: IOfferOutcomeCount[];
  offerOutcomesLoadState: LoadState;
  sessionFilterValues: ISessionFilterValues;
  sessionFilterValuesLoadState: LoadState;
  customerProfile: any;
  customerProfileLoadState: LoadState;
  abSessionCounts: Record<string, number>;
  abSessionCountsLoadState: LoadState;
  segmentSessionCounts: Record<string, number>;
  segmentSessionCountsLoadState: LoadState;
}

export interface IEmailsByDate {
  status: EmailStatus;
  sendDate: Date;
  count: number;
}

export interface IDunningDashboardModuleState {
  dunningEmailTimeline: IEmailsByDate[];
  dunningEmailTimelineLoadState: LoadState;
}

export interface IReactivationDashboardModuleState {
  recentSurveyResponses: { label: string; count: number }[];
  recentSurveyResponsesLoadState: LoadState;
  reactivationEmailTimeline: IEmailsByDate[];
  reactivationEmailTimelineLoadState: LoadState;
}

export interface IEventRollup {
  date: Date;
  dateString: string;
  eventCount: number;
  customerCount: number;
  bucket: 'month' | 'day' | 'year' | 'week';
  org: string;
  updatedAt: string | Date;
}

export interface ISubscriptionAnalyticsModuleState {
  eventRollup: any[];
  eventRollupLoadState: LoadState;
  eventFilterOptions: IEventFilterOptions;
  eventFilterOptionsLoadState: LoadState;
}

export interface IAIMessage {
  type: AIMessageType;
  prompt: string;
}

/* async AI-generation job */
export interface IAIJob {
  _id: string;
  org: string;
  // job instructions
  messages?: IAIMessage[];
  type: AIJobType;
  blueprintGuid: string; // _id
  surveyChoiceGuid?: string; // optional
  stepGuid?: string; // optional
  // job metadata:
  createdAt: Date;
  updatedAt?: Date;
  completedAt?: Date;
  // job outcome:
  status: AIJobStatus;
  error: string;
  progress: number /* placeholder, not used for now */;
  result?: any;
}

export interface ICustomerHealth {
  combinedHealth: {
    timestamp: Date;
    healthScore: number;
    emaFast: number;
    emaSlow: number;
    macd: number;
    risk: 'low' | 'medium' | 'high';
  };
  customer: any; // replace with actual customer type
  subscriptions: any[]; // replace with actual subscription type
}

type PermissionSet = {
  [key: string]: {
    read: boolean;
    write: boolean;
  };
};
export interface IPermissionModuleState {
  userPermissions: PermissionSet;
}

export interface IState
  extends IDashboardModuleState,
    IDunningDashboardModuleState,
    ISubscriptionAnalyticsModuleState,
    IReactivationDashboardModuleState,
    IPermissionModuleState {
  user: null | IUser;
  confidential: boolean;
  testMode: boolean;
  token: null | string;
  authStatus: null | string;
  twoFactorStatus: null | string;
  twoFactorErrorMessage: null | string;
  org: null | IOrg;
  orgs: null | IOrg[];
  syncUserLoadState: LoadState;
  syncOrgLoadState: LoadState;
  customerProfile: null | any;
  customerHealth: null | ICustomerHealth;
  customerActivities: [] | any;
  currentBlueprintId: null | string;
  currentCampaignBlueprintId: null | string;
  currentEmailBlueprintId: null | string;
  blueprints: { [key: string]: IBlueprint };
  segments: { [key: string]: ISegment };
  webhooks: { [key: string]: IWebhook };
  campaignBlueprints: { [key: string]: ICampaignBlueprint };
  campaignBlueprintEmailCounts: { [key: string]: IEmailCount };
  dunningCampaignCounts: { [key: string]: number };
  dunningCampaignCountsLoadState: LoadState;
  dunningExclusions: { [key: string]: IExclusion };
  dunningExclusionBlockCounts: { [key: string]: IBlockCount };
  currentReactivationCampaignBlueprintId: null | string;
  currentReactivationEmailBlueprintId: null | string;
  reactivationCampaignBlueprints: { [key: string]: ICampaignBlueprint };
  reactivationCampaignBlueprintEmailCounts: { [key: string]: IEmailCount };
  reactivationExclusions: { [key: string]: IExclusion };
  reactivationCampaignCounts: { [key: string]: number };
  reactivationCampaignCountsLoadState: LoadState;
  reactivationBlockCounts: { [key: string]: number };
  reactivationBlockCountsLoadState: LoadState;
  reactivationEmailOpenCounts: { [key: string]: IEmailStatusCount };
  reactivationEmailOpenCountsLoadState: LoadState;
  selectedTemplate: string | null;
  stripeData: {
    // coupons that belong to client stripe account
    coupons?: IStripeCoupon[];
    couponsLoadState?: LoadState;
    prices?: IStripePrice[];
    pricesLoadState?: LoadState;
    products?: IStripeProduct[];
    productsLoadState?: LoadState;
  };
  selectedPlanType?: string;
  promo: null | string;
  productIntent: null | string;
  asOrg: null | string;
  previewInline: null | boolean;
  previewMergeFields: null | any;
  issues: IIssue[];
}
export type ActionContext = Vuex.ActionContext<IState, IState>;
export type ActionHandler = Vuex.ActionHandler<IState, IState>;

export const enum LAYOUT {
  NONE = 'NONE',
  SIDEBAR = 'SIDEBAR',
}

export const enum NAV_GROUP {
  MAIN = 'MAIN',
  WORKSPACE = 'WORKSPACE',
  ADMIN = 'ADMIN',
}

export type ValueOrGetter<T> = (state: IState, getters: any) => T;

export type RequiresFeature = 'feedbackAIEnabled' | 'churnMetricsEnabled';

export interface RouteMeta {
  requiresAuth?: boolean;
  requiresAdmin?: boolean;
  requiresRole?: UserRole;
  requiresFeature?: RequiresFeature;
  available?: ValueOrGetter<boolean>;
  layout?: LAYOUT;
  navGroups?: NAV_GROUP[];
  label?: ValueOrGetter<string>;
  icon?: Vue;
  dataControls?: {
    confidential?: ValueOrGetter<boolean>;
    testMode?: ValueOrGetter<boolean>;
  };
}

export interface NavGroup {
  key: NAV_GROUP;
  name?: string;
  routes: RouteConfig[];
}
